<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" aria-label="Change language menu">
        <v-img
            class="locale-switcher"
            max-width="24px"
            :src="localeImage"
            alt="Change language"
          >
        </v-img>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in localeItems"
        :key="index"
        @click="changeLocale(item.title)"
        :aria-label="item.altText"
      >
      <v-list-item-action>
        <v-img
          class="locale-switcher__item"
          :src="item.img"
          :alt="item.altText">
        </v-img>
        </v-list-item-action>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
const enImg = require('@/assets/en.png');
const esImg = require('@/assets/es.png');

export default {
  name: 'LocaleSwitcher',
  data: () => ({
    localeItems: [
      {
        title: 'En',
        altText: 'English',
        img: enImg,
      },
      {
        title: 'Es',
        altText: 'Español',
        img: esImg,
      },
    ],
  }),
  methods: {
    changeLocale(lang) {
      this.$vuetify.lang.current = lang.toLowerCase();
      this.$store.dispatch('SET_LANG', lang.toLowerCase());
    },
  },
  computed: {
    localeImage() {
      const currentLocale = this.localeItems.find((item) => item.title.toLowerCase() === this.$vuetify.lang.current.toLocaleLowerCase());
      return (currentLocale && currentLocale.img) || enImg;
    },
  },
};
</script>

<style scoped lang="scss">
  @supports (-ms-ime-align:auto) {
    .locale-switcher {
      height: 24px;
      &__item {
        height: 24px;
      }
    }
  }
</style>
