/* eslint-disable */
const common = {
  continue: {
    en: 'Continue',
    es: 'Continuar',
  },
  placeFreeze: {
    en: 'Place a freeze',
    es: 'Implementar congelamiento de seguridad',
  },
  clickHere: {
    en: 'click here',
    es: 'haga clic aquí',
  },
  back: {
    en: 'Back',
    es: 'Atrás',
  },
  close: {
    en: 'Close',
    es: 'Cerrar',
  },
  termsOfUse: {
    en: {
      p1: 'YOU MUST READ, UNDERSTAND AND AGREE TO THE FOLLOWING TERMS OF USE AS WELL AS THE GENERAL TERMS OF USE FOUND AT THE LINK AT THE BOTTOM OF THE PAGE IN ORDER TO USE THIS WEB SITE.',
      p2: 'Your Use:',
      p3: 'You certify that you are either requesting on your own personal data report (i) the placement of a security freeze, (ii) a temporary lift of your security freeze to allow access to your data report as permitted by applicable law or (iii) the removal of your security freeze.  You certify that the information you provide to the Exchange Service Center. is accurate and complete, and that by completing any security freeze online transaction, you are authorizing the Exchange Service Center to fulfill your request. You agree to follow all laws and regulations applicable to your making a security freeze request via and use of this web site. You acknowledge that the Exchange Service Center may remove a security freeze if your data report was frozen due to a material misrepresentation of fact. Additionally, making any false statements could be punishable by law.',
      p4: 'You acknowledge that you will receive an online written confirmation once a security freeze request is fulfilled by the Exchange Service Center.  You will not receive any additional confirmation from the Exchange Service Center on your specific security freeze request and the Exchange Service Center recommends you save or print the online written confirmation for your records.  Some of the information on the confirmation may be necessary for future security freeze requests. This web site is provided "as is," with no warranties of any kind.',
    },
    es: {
      p1: 'YOU MUST READ, UNDERSTAND AND AGREE TO THE FOLLOWING TERMS OF USE AS WELL AS THE GENERAL TERMS OF USE FOUND AT THE LINK AT THE BOTTOM OF THE PAGE IN ORDER TO USE THIS WEB SITE.',
      p2: 'Your Use:',
      p3: 'You certify that you are either requesting on your own personal data report (i) the placement of a security freeze, (ii) a temporary lift of your security freeze to allow access to your data report as permitted by applicable law or (iii) the removal of your security freeze.  You certify that the information you provide to the Exchange Service Center. is accurate and complete, and that by completing any security freeze online transaction, you are authorizing the Exchange Service Center to fulfill your request. You agree to follow all laws and regulations applicable to your making a security freeze request via and use of this web site. You acknowledge that the Exchange Service Center may remove a security freeze if your data report was frozen due to a material misrepresentation of fact. Additionally, making any false statements could be punishable by law.',
      p4: 'You acknowledge that you will receive an online written confirmation once a security freeze request is fulfilled by the Exchange Service Center.  You will not receive any additional confirmation from the Exchange Service Center on your specific security freeze request and the Exchange Service Center recommends you save or print the online written confirmation for your records.  Some of the information on the confirmation may be necessary for future security freeze requests. This web site is provided "as is," with no warranties of any kind.',
    },
  },
  freezeNotice: {
    en: {
      p1: 'If you choose to place a security freeze on your data report, be sure to plan ahead for all of your credit applications. Under the laws of some states, it may take up to three business days to process a request to temporarily lift a security freeze. Additionally, you may not be able to request a temporary lift of a security freeze during non-business hours or on weekends.',
    },
    es: {
      p1: 'Si decide implementar un congelamiento de seguridad en su informe de datos, asegúrese de planificar con anticipación todas sus solicitudes de crédito. En virtud de las leyes de algunos estados, puede tomar hasta tres días hábiles procesar una solicitud para levantar temporalmente un congelamiento de seguridad. Además, es posible que no pueda solicitar el levantamiento temporal de un congelamiento de seguridad fuera del horario laboral o los fines de semana.',
    },
  },
  alert: {
    en: {
      title: 'Warning',
      text: 'All information for this request will be lost. Would you like to proceed?',
      yes: 'Yes',
      no: 'No',
    },
    es: {
      title: 'Advertencia',
      text: 'Toda la información para esta solicitud se perderá. Le gustaría continuar?',
      yes: 'Si',
      no: 'No',
    },
  },
  alertSessionTimeout: {
    en: {
      title: 'Warning',
      text: 'Your session has expired. You will be redirected to the home page.',
      ok: 'Ok',
    },
    es: {
      title: 'Advertencia',
      text: 'Su sesión ha caducado. Serás redirigido a la página de inicio.',
      ok: 'Okay',
    },
  },
  footer: {
    en: {
      text1: 'Copyright',
      text2: 'Exchange Service Center',
      termsOfUse: 'Terms of Use',
      privacyPolicy: 'Privacy Policy',
    },
    es: {
      text1: 'Copyright',
      text2: 'Exchange Service Center',
      termsOfUse: 'Términos de uso',
      privacyPolicy: 'Políticas de Privacidad',
    },
  },
  userMenu: {
    en: {
      welcome: 'Welcome',
      exit: 'Exit',
    },
    es: {
      welcome: 'Bienvenido',
      exit: 'Salir',
    },
  },
  message: {
    en: {
      cannotProcessRequest: 'We\'re sorry...we cannot process your online request',
      invalidPin: 'Invalid 10-digit PIN',
    },
    es: {
      cannotProcessRequest: 'Lo sentimos ... no podemos procesar su solicitud en línea',
      invalidPin: 'PIN de 10 dígitos no válido',
    },
  },
};

const getCommonByLang = (lang = 'en') => Object.keys(common)
  .reduce((acc, key) => Object.assign(acc, { [key]: (common[key][lang] || '') }), {});

export default getCommonByLang;
