const FREEZE_OPTIONS = {
  A: {
    code: 'A',
    name: 'PLACE_FREEZE',
    sortOrder: 0,
  },
  PLACE_FREEZE: {
    code: 'A',
  },
  B: {
    code: 'B',
    name: 'PIN_LIFT',
    sortOrder: 2,
  },
  PIN_LIFT: {
    code: 'B',
  },
  C: {
    code: 'C',
    name: 'DATE_RANGE_LIFT',
    sortOrder: 1,
  },
  DATE_RANGE_LIFT: {
    code: 'C',
  },
  D: {
    code: 'D',
    name: 'REMOVE_FREEZE',
    sortOrder: 4,
  },
  REMOVE_FREEZE: {
    code: 'D',
  },
  M: {
    code: 'M',
    name: 'PINS_LIFT',
    sortOrder: 3,
  },
  PINS_LIFT: {
    code: 'M',
  },
};

const FREEZE_CODE_TO_ROUTE_MAP = {
  [FREEZE_OPTIONS.A.code]: '/place-freeze',
  [FREEZE_OPTIONS.B.code]: '/lift-freeze-by-party',
  [FREEZE_OPTIONS.C.code]: '/lift-freeze-by-daterange',
  [FREEZE_OPTIONS.D.code]: '/remove-freeze',
  [FREEZE_OPTIONS.M.code]: '/lift-freeze-by-parties',
};

const SUFFIXES = ['Jr', 'Sr', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'IIX', 'IX'];

const STATES = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID',
  'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE',
  'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN',
  'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY', 'AS', 'DC', 'FM', 'GU', 'MH', 'MP', 'PW',
  'PR', 'VI', 'AE', 'AA', 'AE', 'AE', 'AE', 'AP'].sort();

const PARTIES = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export {
  FREEZE_OPTIONS,
  SUFFIXES,
  STATES,
  PARTIES,
  FREEZE_CODE_TO_ROUTE_MAP,
};
