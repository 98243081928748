<template>
  <v-dialog
    v-model="isDialogShow"
    :max-width="dialogSettings.width"
    :persistent="dialogSettings.persistent"
    @click:outside="clickOutside"
  >
    <component :is="dialogContent"></component>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Dialog',
  components: {
  },
  created() {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) {
        this.clickOutside();
      }
    });
  },
  computed: {
    ...mapState({
      isDialogShow: (state) => state.common.isDialogShow,
      dialogContent: (state) => state.common.dialogContent,
      dialogSettings: (state) => state.common.dialogSettings,
    }),
  },
  methods: {
    clickOutside() {
      if (this.dialogSettings.persistent) return;
      this.$store.dispatch('SET_DIALOG_STATE', false);
    },
  },
};
</script>
