import termsOfUse from '@/components/TermsOfUse.vue';
import privacy from '@/components/Privacy.vue';
import invalidateProfileAlert from '@/components/InvalidateProfileAlert.vue';
import sessionTimeoutAlert from '@/components/SessionTimeoutAlert.vue';

const dialogContent = {
  termsOfUse,
  privacy,
  invalidateProfileAlert,
  sessionTimeoutAlert,
};

export default {
  dialogContent,
};
