/* eslint-disable */
import Config from '@/config';

const SET_LANG = 'SET_LANG';
const SET_DIALOG_STATE = 'SET_DIALOG_STATE';
const SET_DIALOG_CONTENT = 'SET_DIALOG_CONTENT';
const SET_ROUTER_DATA = 'SET_ROUTER_DATA';
const SHOW_SNACK_BAR = 'SHOW_SNACK_BAR';
const SET_TOKEN = 'SET_TOKEN';
const SET_DOC_REF = 'SET_DOC_REF';
const CONFIRMATION_DOWNLOADED = 'CONFIRMATION_DOWNLOADED';
const RETURN_FROM_CONFIRMATION = 'RETURN_FROM_CONFIRMATION';
const SHOW_ERROR = 'SHOW_ERROR';

const defaultDialogSettings = {
  persistent: false,
  width: '70%',
};

const state = {
  isDialogShow: false,
  dialogContent: '',
  dialogSettings: defaultDialogSettings,
  lang: 'en',
  router: {},
  snackBar: {},
  token: '',
  documentReference: '',
  confirmationDownloaded: false,
  returnFromConfirmation: false,
};

const mutations = {
  [SET_LANG](state, payload = '') {
    state.lang = payload;
  },
  [SET_DIALOG_STATE](state, payload = false) {
    state.isDialogShow = payload;
  },
  [SET_DIALOG_CONTENT](state, payload = {}) {
    state.dialogContent = Config.dialogContent[payload.component];
    state.dialogSettings = Object.assign({}, defaultDialogSettings, payload.dialogSettings || {});
  },
  [SET_ROUTER_DATA](state, payload = {}) {
    state.router = payload;
  },
  [SHOW_SNACK_BAR](state, payload = {}) {
    state.snackBar = Object.assign(payload, { show: true });
  },
  [SET_TOKEN](state, payload = '') {
    state.token = payload;
  },
  [SET_DOC_REF](state, payload = '') {
    state.documentReference = payload;
  },
  [CONFIRMATION_DOWNLOADED](state, payload = false) {
    state.confirmationDownloaded = payload;
  },
  [RETURN_FROM_CONFIRMATION](state, payload = false) {
    state.returnFromConfirmation = payload;
  },
};

const actions = {
  [SET_LANG]({ context, commit }, payload) {
    commit(SET_LANG, payload);
  },
  [SET_DIALOG_STATE]({ context, commit }, payload) {
    commit(SET_DIALOG_STATE, payload);
  },
  [SET_DIALOG_CONTENT]({ context, commit }, payload) {
    commit(SET_DIALOG_CONTENT, payload);
    commit(SET_DIALOG_STATE, true);
  },
  [SET_ROUTER_DATA]({ context, commit }, payload) {
    commit(SET_ROUTER_DATA, payload);
  },
  [SHOW_ERROR]({ context, commit }, payload) {
    commit(SHOW_SNACK_BAR, {
      text: payload.response && payload.response.data && payload.response.data.errors && payload.response.data.errors.length
        && payload.response.data.errors[0].message || payload.fallbackMsg,
      color: 'error',
      right: true,
    });
  },
  [SHOW_SNACK_BAR]({ context, commit }, payload) {
    commit(SHOW_SNACK_BAR, payload);
  },
  [SET_TOKEN]({ context, commit }, payload) {
    commit(SET_TOKEN, payload);
  },
  [SET_DOC_REF]({ context, commit }, payload) {
    commit(SET_DOC_REF, payload);
  },
  [CONFIRMATION_DOWNLOADED]({ context, commit }, payload) {
    commit(CONFIRMATION_DOWNLOADED, payload);
  },
  [RETURN_FROM_CONFIRMATION]({ context, commit }, payload) {
    commit(RETURN_FROM_CONFIRMATION, payload);
  },
};

export default {
  state,
  actions,
  mutations,
};
