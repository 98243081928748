<template>

  <v-app-bar
    app
    color="primary"
    dense
    >

    <v-container grid-list-xs fill-height>
      <v-row align="center" dense>

        <router-link to="/">
          <v-img
            class="logo"
            contain
            position="center"
            :src="$vuetify.breakpoint.smAndUp ? require('@/assets/nctue.png') : require('@/assets/nctue_mobile.png')"
            alt="NCTUE Branding Logo"
            :width="logoWidth"
            />
        </router-link>

        <v-spacer></v-spacer>

        <v-toolbar-title :style="titleStyles">
          <h1 :class="titleClasses">
            <router-link class="white--text" style="text-decoration: none;" to="/">Exchange Service Center</router-link>
          </h1>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <user-profile />
        <locale-switcher/>

      </v-row>
    </v-container>

  </v-app-bar>
</template>

<script>
import localeSwitcher from '@/components/LocaleSwitcher.vue';
import userProfile from '@/components/UserProfile.vue';

export default {
  name: 'Toolbar',
  components: {
    localeSwitcher,
    userProfile,
  },
  computed: {
    titleClasses() {
      return (this.$vuetify.breakpoint.mdAndUp && 'display-1') || (this.$vuetify.breakpoint.smAndUp && 'headline') || 'title';
    },
    logoWidth() {
      return (this.$vuetify.breakpoint.mdAndUp && 180) || (this.$vuetify.breakpoint.smAndUp && 100) || 60;
    },
    titleStyles() {
      return { 'margin-left': (this.$vuetify.breakpoint.mdAndUp && '-130px') || (this.$vuetify.breakpoint.smAndUp && '-50px') || '' };
    },
  },
};
</script>

<style lang="scss">
  header {
    .v-toolbar__content {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @supports (-ms-ime-align:auto) {
    .logo {
      height: 50px;
    }
  }
</style>
