<template>
  <v-alert
    class="body-2 font-weight-medium ma-0"
    border="left"
    colored-border
    prominent
    type="warning"
    lang="common.alertSessionTimeout.text"
  >
    <v-card flat>
      <v-card-title class="subtitle-1 text-uppercase warning--text py-0 mb-10" lang="common.alertSessionTimeout.title">
        {{ t('common.alertSessionTimeout.title') }}
      </v-card-title>
      <v-card-text class="title font-weight-light black--text text--lighten-3" lang="common.alertSessionTimeout.text">
        {{ t('common.alertSessionTimeout.text') }}
      </v-card-text>
      <v-divider class="ma-4"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="invalidateSession"
            lang="common.alertSessionTimeout.ok"
        >{{ t('common.alertSessionTimeout.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-alert>
</template>

<script>
import lang from '@/mixins/lang';

export default {
  mixins: [lang],
  methods: {
    invalidateSession() {
      this.$store.dispatch('INVALIDATE_PROFILE');
      this.$router.push('/');
    },
  },
};
</script>
