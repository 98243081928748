import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import lang from '@/i18n/i18n';

Vue.use(Vuetify);

const theme = {
  themes: {
    light: {
      primary: '#4d79af',
      error: '#ee0000',
    },
  },
};

export default new Vuetify({
  lang,
  theme,
});
