<template>
  <v-app id="app">

    <toolbar />

    <v-content>
      <v-container white fluid>
        <breadcrumb />

        <v-row>
          <v-col cols="12">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <footer-cmp />
    <app-dialog></app-dialog>
    <snack-bar></snack-bar>

  </v-app>
</template>

<script>
import appDialog from '@/components/Dialog.vue';
import toolbar from '@/components/Toolbar.vue';
import footerCmp from '@/components/Footer.vue';
import breadcrumb from '@/components/Breadcrumb.vue';
import snackBar from '@/components/SnackBar.vue';

export default {
  components: {
    toolbar,
    footerCmp,
    breadcrumb,
    appDialog,
    snackBar,
  },
};
</script>

<style lang="scss">
.v-application {
  &.theme--light {
    background-color: #fff!important;
  }

  .v-input__slot .theme--light.v-label {
    color: rgba(0,0,0,.65);
    font-size: .95rem;
  }

  .efx-sheet {
    .v-input__slot .theme--light.v-label {
      color: rgba(0,0,0,.75);
    }
  }

  a {
    color: #1f33ea!important;
  }

  .v-btn.v-btn--contained.v-btn--router {
    color: #fff!important;
  }
}
</style>
