<template>
  <v-menu offset-y v-if="profile" lang="common.alert.userMenu">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" aria-label="User profile menu">
        <v-icon color="white">account_circle</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-subheader style="height:20px">{{ t('common.userMenu.welcome') }},</v-subheader>
      <v-subheader class="font-weight-bold">{{ profile.firstName }} {{ profile.lastName }}
      </v-subheader>
      <v-divider></v-divider>
      <v-list-item @click="logOut()" color="primary" aria-label="Exit">
        <v-list-item-action>
          <v-icon color="primary">exit_to_app</v-icon>
        </v-list-item-action>
        <v-list-item-title @click="logOut">{{ t('common.userMenu.exit') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';
import lang from '@/mixins/lang';

export default {
  name: 'UserProfile',
  mixins: [lang],
  data: () => ({
  }),
  methods: {
    logOut() {
      this.$router.push('/');
    },
  },
  computed: {
    ...mapState({
      profile: (state) => state.consumer.profiles[0],
    }),
  },
};
</script>

<style lang="scss">
  .theme--light.v-subheader {
    color: rgba(0,0,0,.75)!important;
  }
</style>
