<template>
  <v-snackbar
    v-model="snackBar.show"
    v-bind="snackBar"
  >
    {{ snackBar.text }}
    <v-btn
      dark
      text
      @click="snackBar.show = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    args: {
      right: true,
    },
  }),
  computed: {
    ...mapState({
      snackBar: (state) => state.common.snackBar,
    }),
  },
};
</script>
