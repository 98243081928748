<template>
  <v-footer padless class="caption">
    <v-flex
        text-center
        xs12
      >
        <div>
          <span>{{ t('common.footer.text1') }} {{ new Date().getFullYear() }} {{ t('common.footer.text2') }}</span>
          <span>&nbsp;|&nbsp;</span>
          <a
            href="javascript:void(0)"
            class="text-capitalize"
            @click.stop.prevent="$store.dispatch('SET_DIALOG_CONTENT', { component: 'termsOfUse' })">
            {{ t('common.footer.termsOfUse') }}</a>
            <span>&nbsp;|&nbsp;</span>
          <a
            href="javascript:void(0)"
            class="text-capitalize"
            @click.stop.prevent="$store.dispatch('SET_DIALOG_CONTENT', { component: 'privacy' })">
            {{ t('common.footer.privacyPolicy') }} </a>
        </div>
    </v-flex>
  </v-footer>
</template>

<script>
import lang from '@/mixins/lang';

export default {
  name: 'Footer',
  mixins: [lang],
};
</script>
