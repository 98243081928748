/* eslint-disable */
const SET_PROFILE = 'SET_PROFILE';
const SET_FREEZE_OPTION = 'SET_FREEZE_OPTION';
const INVALIDATE_PROFILE = 'INVALIDATE_PROFILE';
const ADD_INVALID_PIN = 'ADD_INVALID_PIN';
const RESET_INVALID_PINS = 'RESET_INVALID_PINS';
const SET_USER_PIN = 'SET_USER_PIN';

const state = {
  profiles: [],
  freezeOption: '',
  invalidPins: [],
  userPin: '',
};

const mutations = {
  [SET_PROFILE](state, payload = []) {
    state.profiles = payload;
  },
  [SET_FREEZE_OPTION](state, payload = '') {
    state.freezeOption = payload;
  },
  [INVALIDATE_PROFILE](state, payload = {}) {
    state.router = payload;
  },
  [ADD_INVALID_PIN](state, payload = '') {
    if (payload && payload.length === 10) {
      state.invalidPins.push(payload);
    }
  },
  [RESET_INVALID_PINS](state) {
    state.invalidPins = [];
  },
  [SET_USER_PIN](state, payload = '') {
    state.userPin = payload;
  },
};

const actions = {
  [SET_PROFILE]({ commit }, payload) {
    commit(SET_PROFILE, payload);
  },
  [SET_FREEZE_OPTION]({ commit }, payload) {
    commit(SET_FREEZE_OPTION, payload);
  },
  [INVALIDATE_PROFILE]({ commit }) {
    commit(SET_PROFILE, []);
    commit(SET_FREEZE_OPTION, '');
    commit('SET_DIALOG_STATE', false);
    commit('SET_TOKEN', '');
    commit('SET_DOC_REF', '');
    commit('RESET_INVALID_PINS');
    commit('SET_USER_PIN', '');
  },
  [ADD_INVALID_PIN]({ commit }, payload) {
    commit(ADD_INVALID_PIN, payload);
  },
  [RESET_INVALID_PINS]({ commit }) {
    commit(RESET_INVALID_PINS);
  },
  [SET_USER_PIN]({ commit }, payload) {
    commit(SET_USER_PIN, payload);
  },
};

const getters = {
  profile(state) {
    return state.profiles[0];
  },
  availableActions(state) {
    return state.profiles[0] && state.profiles[0].availableActions || [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
