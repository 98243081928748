import Vue from 'vue';
import Vuex from 'vuex';
import common from '@/store/common';
import consumer from '@/store/consumer';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    consumer,
  },
});
