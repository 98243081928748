<template>
  <v-row v-if="breadcrumb.length">
    <v-col cols="12" class="pa-0">
      <v-breadcrumbs :items="breadcrumb">
        <template v-slot:item="props">
          <v-breadcrumbs-item :lang="props.item">
            {{ t(props.item) }}
          </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
          <v-icon>chevron_right</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
    <v-col cols="12" class="py-0">
      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script>
import lang from '@/mixins/lang';

export default {
  name: 'Breadcrumb',
  mixins: [lang],
  data: () => ({
  }),
  computed: {
    breadcrumb() {
      return this.$route.meta.breadcrumb || [];
    },
  },
};
</script>

<style lang="scss">
  .v-breadcrumbs {

    li {
      font-weight: bold;

      &:last-child {
        color:#025086 !important;
        font-size: 1rem;

      }
    }
  }
</style>
