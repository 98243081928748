/* eslint-disable */
import getCommonByLang from './common';

export default {
  common: getCommonByLang('en'),
  verification: {
    title: 'Let\'s get started',
    startText: {
      p1: 'Welcome to the Exchange Service Center Website. To request a security freeze be placed, temporarily lifted, or permanently removed from your Exchange Service Center Data Report, please provide your personal ID information requested below. Your personal ID information will only be used for this security freeze request and will not be used for marketing purposes.',
      p2: 'This information is needed to verify your identity. Form fields designated with an asterisk (*) are required.',
    },
    form: {
      firstName: 'First Name',
      lastName: 'Last Name',
      initial: 'Middle Initial',
      suffix: 'Suffix',
      ssn: 'Social Security Number',
      dob: 'Date of Birth',
      address: 'Current Address',
      city: 'City',
      state: 'State',
      zip: 'ZIP Code',
      accept: 'I Accept the ',
      continueText: 'To continue using the Exchange Service Center Security Freeze Website, please accept the below terms of use.',
      termsOfUse: 'Terms of use',
      validation: {
        requiredFirstName: 'Please enter first name',
        requiredLastName: 'Please enter last name',
        requiredSsn: 'Please enter SSN',
        requiredBirthdate: 'Please enter date of birth (mm/dd/yyyy)',
        requiredAddress: 'Please enter current address',
        requiredCity: 'Please enter city',
        requiredState: 'Please select state',
        requiredZip: 'Please enter ZIP code',
        requiredAcceptTerms: 'You must agree to continue!',
        name: 'Please enter only letters, hyphens, or spaces',
        address: 'Please Enter Only Numbers, Letters, Spaces, \'-\', \'.\' or \'/\'.',
        noNumbersInAddress: 'Please Enter Apartment Number, House Number Or P.O.Box Number In The Address field',
        noLettersInAddress: 'Please Enter The House/Plot Name, Street Name, Area Name',
        city: 'Please enter only letters, periods, hyphens and spaces',
        zip: 'Please enter 5 digits of ZIP code',
        ssn: 'Incorrect SSN. Expected format: XXX-XX-XXXX',
        date: 'Invalid date',
      },
    },
  },
  additionalInfo: {
    title: 'Additional Information Required',
    p1: 'We\'re sorry...we cannot process your online request concerning an Exchange Service Center security freeze. To assist us in processing your request, please submit in writing the required items outlined below.',
    p2: 'For Assistance: Monday - Friday 8:00 am - 5:00 pm in your time zone please call 1-866-343-2821',
    p4: 'For all security freeze requests, please submit the following ID information: your complete name including any suffix (e.g. Jr., Sr., etc.), complete address, Social Security number and date of birth.',
    p5: 'For the Placement of a security freeze, please provide the above required personal ID information.',
    p6: 'To temporarily lift a security freeze, you must submit all of the following along with the required personal ID information:',
    li1: '10-digit personal identification number (PIN)',
    li2: 'Date range (e.g. March 15 - March 21) for the temporary lifting of the security freeze, or proper information regarding the third party or parties to receive your data report',
    p7: 'To permanently remove a security freeze, you must submit all of the following along with the required personal ID information:',
    li3: '10-digit personal identification number (PIN)',
    li4: 'Two (2) forms of identification (e.g. Pay stub with address and utility bill)',
    p8: 'Please return your required personal ID information along with any additional personal information noted above to:',
    p9: 'Exchange Service Center',
    p10_1: 'Security Freeze',
    p10_2: 'P.O. Box 105561',
    p10_3: 'Atlanta, GA 30348',
    p11: 'Thank you,',
    p12: 'Exchange Service Center',
    btn1: 'Return to Verification',
  },
  serviceOptions: {
    title1: 'Your',
    title2: 'National Consumer Telecommunications and Utilities Data Exchange (NCTUE)',
    title3: 'Data report is ',
    title4_1: 'Frozen',
    title4_2: 'not Frozen',
    p1: 'You may place, temporarily lift or remove a security freeze on your Telecom and Utilities data report under state law or the Exchange Service Center voluntary security freeze program.',
    p2: 'A security freeze is designed to prevent the information in your Exchange Service Center data report from being reported to others, such as service providers and other companies, except those exempted by law or those for whom you contacted us and requested that we temporarily lift the security freeze or those that access during a period of time when you requested we temporarily lift the security freeze.',
    p3: 'Although some state laws may allow us to charge for security freeze requests, Exchange Service Center never charges any fees for any type of security freeze request.',
    p4: 'Your National Consumer Telecommunications and Utilities Data Exchange (NCTUE) Data report is Frozen',
    p5_1: 'If you would like to review general information about security freezes, please ',
    p5_2: '.',
    btnManageFreeze: 'Manage freeze',
    btnPlaceFreeze: 'Place a freeze',
  },
  generalInfo: {
    title: 'General Information',
    s1: 'What it is:',
    s1p1: 'You may place, temporarily lift or remove a security freeze on your Telecom and Utilities data report under state law or the Exchange Service Center voluntary security freeze program. A security freeze is designed to prevent the information in your Exchange Service Center data report from being reported to others, such as service providers and other companies, except those exempted by law or those for whom you contacted us and requested that we temporarily lift the security freeze or those that access during a period of time when you requested we temporarily lift the security freeze. Although some state laws may allow us to charge for security freeze requests, Exchange Service Center never charges any fees for any type of security freeze request.',
    s2: 'Who can request:',
    s2p1: 'Only you can request that a security freeze be placed on your Exchange Service Center data report and only you can request that it be removed or temporarily lifted. Additionally, the security freeze will generally remain on your Exchange Service Center data report until: 1) you request that it be removed or 2) you request a temporary lift of the security freeze for a specific party or parties, or specific period of time, as applicable under state law or the Exchange Service Center voluntary security freeze program.',
    s2p2: 'In addition to the adult security freeze laws, many states also have a protected consumer security freeze law that allows a parent or guardian to place a freeze on the data report of the minor or incapacitated adult for whom they are responsible. You must provide proof of your identity as the protected consumer\'s representative, proof of the protected consumer\'s identity, and proof of your authority to act on behalf of the protected consumer. All protected consumer security freeze requests should be sent by mail to the address as shown below.',
    s3: 'Need to Plan Ahead:',
    s3p1: 'If you choose to place a security freeze on your data report, be sure to plan ahead for all of your credit applications. Under the laws of some states, it may take up to three business days to process a request to temporarily lift a security freeze. Additionally, you may not be able to request a temporary lift of a security freeze during non-business hours or on weekends.',
    s4: 'Submitting Freeze Request by Mail:',
    s4p1: 'Although some states allow a security freeze to be placed, temporarily lifted or removed in other ways, in most cases to request that a security freeze be placed or removed on your Exchange Service Center data report, please make your request in writing to us by mail.',
    s4p2_1: 'To submit your security freeze requests via mail, please submit the following personal ID information: ',
    s4p2_2: 'your complete name, including any suffix (e.g. Jr., Sr., etc.), complete address, Social Security number and date of birth.',
    s4l1_1: 'For the placement of a security freeze, ',
    s4l1_2: 'provide the above required personal ID information via mail.',
    s4l2_1: 'To temporarily lift a security freeze, ',
    s4l2_2: 'you must submit all of the following along with the required personal ID information',
    s4l2l1: '10 digit personal identification number (PIN)',
    s4l2l2: 'Date range, if your state allows for a date range lift, (e.g. March 15 - March 21) for the temporary lifting of the security freeze, or',
    s4l2l3: 'Proper information regarding the third party to receive your data report, if your state allows for a party(ies) lift.',
    s4l3_1: 'To permanently remove a security freeze, ',
    s4l3_2: 'you must submit all of the following along with the required personal ID information',
    s4l3l1: '10 digit personal identification number (PIN)',
    s4l3l2: 'Two (2) forms of identification (e.g. pay stub with address and utility bill showing current address)',
    s4l4_1: 'To request a replacement 10 digit personal identification number (PIN), ',
    s4l4_2: 'you must submit the following along with the required personal ID information',
    s4l4l1: 'Your complete name, including any suffix (e.g. Jr., Sr., etc.), complete address, Social Security number and date of birth',
    s4l4l2: 'Two (2) forms of identification (e.g. pay stub with address and utility bill showing current address)',
    s4p3: 'Please return your required personal ID information along with any additionally required information noted above to:',
    s5l1: 'Exchange Service Center',
    s5l2: 'P.O. Box 105561',
    s5l3: 'Atlanta, GA 30348',
    s6: 'Charges for Security Freezes:',
    s6p1: 'Although some state laws may allow us to charge fees for certain security freeze requests, Exchange Service Center does not charge any fees for a replacement PIN, or to place, temporarily lift, or permanently remove a security freeze.',
    s7: 'Security Freeze Request Confirmation:',
    s7p1: 'Once we place, temporarily lift or remove a security freeze on your Exchange Service Center data report, we will provide you confirmation. Be sure to retain the 10-digit security freeze personal identification number (PIN) provided to you when you place the security freeze, as you will need this to request a temporary lift or removal of your security freeze. If you lose your PIN, you may request a new PIN by sending a request via mail to the address shown above.',
    s8: 'Equifax/Experian/TransUnion:',
    s8p1: 'To place a security freeze request with the nationwide consumer credit reporting agencies, please contact Equifax, Experian and TransUnion:',
    s8l1: 'Equifax, P.O. Box 105788, Atlanta, GA 30348-5788, (888) 298-0045 or www.equifax.com',
    s8l2: 'TransUnion LLC, P.O. Box 2000, Chester, PA 19016, (800) 680-7289 or www.transunion.com',
    s8l3: 'Experian, P.O. Box 9554, Allen, TX 75013, (888) 397-3742 or www.experian.com',
    s8p2: ' If you need assistance, you may contact the Exchange Service Center at 1-866-343-2821',
    title2: 'Credit Protection FAQs',
    s9: 'Questions we hear. Answers you need.',
    q: 'Q.',
    a: 'A.',
    s10: 'What rights do I have to remedy the effects of fraud or identity theft?',
    s10p1: 'You can learn about your rights under the federal Fair Credit Reporting Act (FCRA) and your rights provided by the FTC by visiting',
    s10l1: 'https://www.identitytheft.gov/',
    s10l2: 'https://files.consumerfinance.gov/f/documents/bcfp_consumer-identity-theft-rights-summary_2018-09.docx',
    s10p2: 'or',
    s11: 'How can I remove a fraud alert from my data report?',
    s11p1: 'A written request is required to remove a fraud alert from your file. You may write to Exchange Service Center at the following address:',
    s11a00: 'Exchange Service Center',
    s11a01: 'PO Box 105425',
    s11a02: 'Atlanta, GA 30348-5425',
    s11p3: 'Please be sure to include your name, social security number, current and previous addresses, date of birth, and telephone number.',
    s12: 'Important Contact Information for Victims of Identity Theft?',
    s12p1: 'There are a number of helpful services to help you respond if you have been a victim of identity theft. Below is a list of resources that we have compiled on your behalf.',
    s12a10: 'Federal Trade Commission\'s Identity Theft Hotline',
    s12a11: '877/ID-THEFT',
    s12a20: 'Equifax Information Services LLC',
    s12a21: '800-525-6285',
    s12a22: 'P.O. Box 740250',
    s12a23: 'Atlanta, GA 30374',
    s12a30: 'www.fraudalerts.equifax.com',
    s12a40: 'Experian fraud division',
    s12a41: ' 888-397-3742',
    s12a42: ' P.O. Box 1017',
    s12a43: 'Allen, TX 75013',
    s12a50: 'Trans Union fraud division',
    s12a51: '800-916-8800',
    s12a52: 'P.O. Box 2000',
    s12a53: 'Chester, PA 19022',
    s13: 'My spouse or parent has died, what happens to their data report?',
    s13p1: 'You should notify Exchange Service Center of your spouse\'s or parent\'s passing, so that Exchange Service Center can update its records accordingly. Write to:',
    s13a00: 'Exchange Service Center',
    s13a01: 'P.O. Box 105398',
    s13a02: 'Atlanta GA 30348-5398',
    s13a03: 'Please include a copy of the death certificate. ',
    b1: 'Return to freeze options',
  },
  manageFreeze: {
    p1: 'Please select which Security Freeze option you would like to request (you may only select one option at a time):',
    opt_C: 'Temporarily lift a Security Freeze by Date Range',
    opt_B: 'Temporarily lift a Security Freeze by Specific Party',
    opt_M: 'Temporarily lift a Security Freeze by Multiple Parties',
    opt_D: 'Permanently remove a Security Freeze',
  },
  placeFreeze: {
    p1: 'You\'re about to ',
    p1_1: 'place freeze',
    p1_2: ' on your National Consumer Telecommunications and Utilities Data Exchange (NCTUE) Data Report',
  },
  confirmation: {
    title1: 'You have successfully',
    title2: 'on your National Consumer Telecommunications and Utilities Data Exchange (NCTUE) Data Report',
    opt_A: 'placed a freeze',
    opt_B: 'Temporarily Lifted your Security Freeze',
    opt_C: 'Temporarily Lifted your Security Freeze',
    opt_D: 'Permanently Removed your Security Freeze',
    opt_M: 'Temporarily Lifted your Security Freeze',
    print_A: 'Please print and keep the below document for your records. You must print the document using the Adobe Print button below. The information provided is required in order to temporarily lift or permanently remove the security freeze from your credit file in the future.',
    print_B: 'Please print and keep the below document for your records. You must print the document using the Adobe Print button below.',
    print_C: 'Please print and keep the below document for your records. You must print the document using the Adobe Print button below.',
    print_D: 'Please print and keep the below document for your records. You must print the document using the Adobe Print button below.',
    print_M: 'Please print and keep the below document for your records. You must print the document using the Adobe Print button below.',
    download: 'download confirmation (pdf)',
    return: 'return to freeze options',
    text1: 'If you are unable to view your one-time PDF, please call 1-866-343-2821.',
    text2: 'To make a security freeze request with the other national consumer credit reporting agencies, please contact Equifax, Experian and TransUnion:',
    text3: 'Equifax, P.O. Box 105788 Atlanta, GA 30348-5788: (800)349-9960',
    text4: 'Experian, P.O. Box 9554, Allen, TX 75013 (888)379-3742',
    text5: 'TransUnion, P.O. Box 6790, Fullerton, CA 92834 (888)909-8872',
  },
  liftFreeze: {
    title1: 'Security Freeze Lift by Date Range',
    p1: 'You are about to temporarily lift the Security Freeze on your  National Consumer Telecommunications and Utilities Data Exchange (NCTUE) credit file.',
    title2: 'Temporarily lift a Security Freeze by Date Range:',
    p2: 'Please specify the 10 digit PIN associated with the security freeze on your  National Consumer Telecommunications and Utilities Data Exchange (NCTUE) credit file. Your 10 digit PIN is required in order to request that your security freeze be temporarily lifted.',
    p3: 'Please specify the 10 digit PIN associated with the security freeze on your  National Consumer Telecommunications and Utilities Data Exchange (NCTUE) credit file. Your 10 digit PIN is required in order to request that your security freeze be permanently removed.',
    titleMP1: 'Security Freeze Temporary Lift by Multiple Parties',
    titleMP2: 'Temporarily lift my security freeze for the number of parties below.',
    titleMP2note1: 'You may choose between ',
    titleMP2note2: ' and ',
    titleMP2note3: ' parties below',
    titleSP1: 'Security Freeze Temporary Lift by Specific Party',
    titleSP2: 'Temporarily lift my Security Freeze for the Party below:',
    form: {
      dateFrom: 'From',
      dateTo: 'To',
      pin: '10-digit PIN',
      parties: 'Number of parties',
      partyName: 'Party Name',
      validation: {
        requiredFrom: 'Please enter start date (mm/dd/yyyy)',
        requiredTo: 'Please enter end date (mm/dd/yyyy)',
        requiredPinCode: 'Please enter 10-digit PIN',
        requiredParties: 'Please select the number of parties',
        requiredPartyName: 'Enter the party name',
        pin: 'Please enter only numbers',
        party: 'Enter only alphabets for party name',
        dateRange1: 'Date cannot be lesser than the current date',
        dateRange2: 'The From Date cannot be the same or greater than the To Date',
        dateRange3: 'Difference between the From Date and the To Date cannot be more than a year',
      },
    },
  },
  removeFreeze: {
    title1: 'Permanently Remove Security Freeze',
    p1: 'You are about to permanently remove the security freeze on your  National Consumer Telecommunications and Utilities Data Exchange (NCTUE) credit file.',
    title2: 'Permanently remove security freeze:',
  },
  breadcrumb: {
    verification: 'Verification',
    additionalInformation: 'Additional Information',
    serviceOptions: 'Service Options',
    generalInfo: 'General Info',
    manageFreeze: 'Manage Freeze',
    placeFreeze: 'Place Freeze',
    liftFreeze: 'Lift Freeze',
    removeFreeze: 'Remove Freeze',
    confirmation: 'Confirmation',
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    title2: 'Exchange Service Center – Online Privacy Policy',
    s1p1: 'Welcome to the Exchange Service Center web site. The Exchange Service Center provides consumer services on behalf of the National Consumer Telecommunications Utility Exchange (NCTUE). The Exchange Service Center is operated by Equifax.',
    s1p2: 'At this web site, you can add, temporarily lift, or permanently remove a security freeze from your Exchange Data Report. Please carefully read this privacy policy to understand how we will treat the information you provide while visiting the web site. This policy may change from time to time. Please check the policy each time you use our Web site for the most current information',
    s1p3: 'We take reasonable steps to give you, as a visitor to this web site, clear notice of:',
    s1p4: 'We take reasonable steps to:',
    s1l11: 'when we are requesting information from you;',
    s1l12: 'the types of information we request from you;',
    s1l13: 'the general purposes for which that information will be used or disclosed;',
    s1l14: 'the categories of users to whom we provide the information; and',
    s1l21: 'Maintain accurate records and provide you access to your Exchange Data Report and a way to update and correct such information;',
    s1l22: 'Use safe and secure systems, including physical, administrative, and technical security procedures to safeguard the information about you.',
    title3: 'COLLECTION AND USE OF INFORMATION',
    s2t1: 'Information You Provide',
    s2t1p1: 'When you provide information through this web site to request a security freeze, a temporary lift, or a permanent removal of a security freeze on your Exchange Data Report online, the information we collect from you is only used to handle those requests. You may provide to us certain personally identifiable consumer information, such as first and last name; home address, billing address, or other physical address; a Social Security number; or certain other information, such as date of birth, and credit card account information if payment is required (collectively, “Personally Identifiable Consumer Information”). In general, we will use the information collected from you for the purpose of fulfilling your security freeze request. We will not release your Personally Identifiable Consumer Information to third parties except: (1) as necessary for us to process your security freeze request or (2) as permitted by law. These third parties are not allowed to use Personally Identifiable Consumer Information except for the purpose of providing these services.',
    s2t2: 'Information From Others',
    s2t2p1: 'We may also collect information about your transactions with us, our affiliates, or others, such as NCTUE, in connection with fulfilling your security freeze requests.',
    s2t3: 'How We Use The Information We Collect',
    s2t3p1: 'We use the information we collect about you to handle the security freeze requests you make to us. If you request a security freeze, a temporary lift, or a permanent removal of a security freeze on your Exchange Data Report online, the information we collect from you is only used to handle those requests and possibly update your Exchange Data Report.',
    s2t4: 'To Whom We May Disclose The Information We Collect',
    s2t4p1: 'When you request a security freeze, a temporary lift, or a permanent removal of a security freeze on your Exchange Data Report online, we may disclose any of the information we collect from or about you, as described above, to affiliated companies, as well as non-affiliated third parties (companies that are not owned or controlled by us). The information provided to affiliates and non-affiliated third parties is disclosed only as required to process and fulfill these requests for you.',
    s2t4p2: 'We take reasonable precautions to be sure that nonaffiliated third parties and affiliates, to whom we may disclose Personally Identifiable Consumer Information, as described above, are aware of our privacy policy and fair information principles and will treat the information in a similarly responsible manner. Our contracts and written agreement with nonaffiliated third parties that receive information from us about you prevent further transfer of the information.',
    s2t4p3: 'We may also disclose any information collected from you or about you through this web site as required by law or legal process.',
    s2t5: 'ACCESS TO YOUR INFORMATION',
    s2t5p1: 'You may order a disclosure copy of your Exchange Data Report by mailing your request to Exchange Service Center, PO Box 105161, Atlanta, GA 30348 or by calling 866-343-2821. If you believe that there is an error on your Exchange Data Report, you may file a dispute by writing to Exchange Service Center, PO Box 105398, Atlanta, GA 30348.',
    s2t6: 'SECURITY AND CONFIDENTIALITY',
    s2t6p1: 'The Exchange Service Center recognizes the importance of secure online transactions, and we maintain reasonable physical, administrative, and technical safeguards to protect your Personally Identifiable Consumer Information and the other information we collect about you, as described above. We safeguard the privacy of information you provide us through online forms. For online requests, we use programs that encrypt the information you provide on the form before transmission to the Exchange Service Center. Information you provide to us online, including Personally Identifiable Consumer Information is transmitted to us through a secured socket layer (SSL) transmission. The information is decrypted only upon receipt by The Exchange Service Center.',
    s2t6p2: 'Except as stated in the "To Whom We May Disclose The Information We Collect" section above, we restrict access to Personally Identifiable Consumer Information that is collected about you to only those who have a need to know that information in connection with the purposes for which it is collected and used. This web site does not use or collect cookies.',
    s2t6p3: 'Additionally, we have security protocols and measures in place to reasonably protect the Personally Identifiable Consumer Information and other information we maintain about you from unauthorized access or alteration. These measures include internal and external firewalls, physical security and technological security measures, and encryption of certain data.',
    s2t7: 'CHILDREN\'S PRIVACY',
    s2t7p1: 'This web site where we may collect Personally Identifiable Consumer Information in conjunction with a transaction involving a request for initial placement, a temporary lift, or a permanent removal of a security freeze on your Exchange Data Report online, is intended for use only by adults. We comply with the Children\'s Online Privacy Protection Act and do not knowingly solicit or collect information from children.',
    s2t8: 'APPLICABILITY OF PRIVACY POLICY TO INDIVIDUAL USE ONLY',
    s2t8p1: 'This online privacy policy applies to individuals using this web site to request the services provided through this web site for personal, family or household purposes.',
    s2t9: 'KEEPING UP-TO-DATE ON OUR PRIVACY POLICY CHANGES',
    s2t9p1: 'We want you to be informed about how we protect your privacy. We may change our privacy policy in the future, but we will not change our practices until they have been posted at this web site. If changes occur, we will also show the date of revision. By using our web site to which this privacy policy applies you agree that we may notify you of changes in our privacy policy through this web site.',
    s2t10: 'HOW TO CONTACT US WITH QUESTIONS OR COMMENTS',
    s2t10p1: 'If you have questions or comments about Exchange Service Center’s online privacy policy and fair information practices, please e-mail us at NCTUE@exchangeservicecenter.com or write to Exchange Service Center, P.O. Box 105832, Atlanta, GA 30348. If you have specific questions about your Exchange Data Report you may e-mail us at NCTUE@exchangeservicecenter.com.',
    s2t10p2: 'Effective Date: February 17, 2011',
  },
};
