<template>
  <v-card>
    <v-card-title class="title grey lighten-2 mb-5" lang="privacyPolicy.title">{{ t('privacyPolicy.title') }}</v-card-title>
    <v-card-text class="subtitle-2 font-weight-light black--text text--lighten-3">
      <h2 class="headline text-center text-capitalize mb-5" lang="privacyPolicy.title2">{{ t('privacyPolicy.title2') }}</h2>

      <p lang="privacyPolicy.s1p1">{{ t('privacyPolicy.s1p1') }}</p>
      <p lang="privacyPolicy.s1p2">{{ t('privacyPolicy.s1p2') }}</p>

      <p lang="privacyPolicy.s1p3">{{ t('privacyPolicy.s1p3') }}</p>
      <ul class="pad-bottom">
        <li lang="privacyPolicy.s1l11">{{ t('privacyPolicy.s1l11') }}</li>
        <li lang="privacyPolicy.s1l12">{{ t('privacyPolicy.s1l12') }}</li>
        <li lang="privacyPolicy.s1l13">{{ t('privacyPolicy.s1l13') }}</li>
        <li lang="privacyPolicy.s1l14">{{ t('privacyPolicy.s1l14') }}</li>
      </ul>

      <p lang="privacyPolicy.s1p4">{{ t('privacyPolicy.s1p4') }}</p>
      <ul class="pad-bottom">
        <li lang="privacyPolicy.s1l21">{{ t('privacyPolicy.s1l21') }}</li>
        <li lang="privacyPolicy.s1l22">{{ t('privacyPolicy.s1l22') }}</li>
      </ul>

      <h2 class="headline text-capitalize mb-5" lang="privacyPolicy.title3">{{ t('privacyPolicy.title3') }}</h2>

      <h3 class="title text-left mb-3" align="left" lang="privacyPolicy.s2t1">{{ t('privacyPolicy.s2t1') }}</h3>
      <p lang="privacyPolicy.s2t1p1">{{ t('privacyPolicy.s2t1p1') }}</p>

      <h3 class="title text-left mb-3" align="left" lang="privacyPolicy.s2t2">{{ t('privacyPolicy.s2t2') }}</h3>
      <p lang="privacyPolicy.s2t2p1">{{ t('privacyPolicy.s2t2p1') }}</p>

      <h3 class="title text-left mb-3" align="left" lang="privacyPolicy.s2t3">{{ t('privacyPolicy.s2t3') }}</h3>
      <p lang="privacyPolicy.s2t3p1">{{ t('privacyPolicy.s2t3p1') }}</p>

      <h3 class="title text-left mb-3" align="left" lang="privacyPolicy.s2t4">{{ t('privacyPolicy.s2t4') }}</h3>
      <p lang="privacyPolicy.s2t4p1">{{ t('privacyPolicy.s2t4p1') }}</p>
      <p lang="privacyPolicy.s2t4p2">{{ t('privacyPolicy.s2t4p2') }}</p>
      <p lang="privacyPolicy.s2t4p3">{{ t('privacyPolicy.s2t4p3') }}</p>

      <h2 class="headline text-capitalize mb-5" lang="privacyPolicy.s2t5">{{ t('privacyPolicy.s2t5') }}</h2>
      <p lang="privacyPolicy.s2t5p1">{{ t('privacyPolicy.s2t5p1') }}</p>

      <h2 class="headline text-capitalize mb-5" lang="privacyPolicy.s2t6">{{ t('privacyPolicy.s2t6') }}</h2>
      <p lang="privacyPolicy.s2t6p1">{{ t('privacyPolicy.s2t6p1') }}</p>
      <p lang="privacyPolicy.s2t6p2">{{ t('privacyPolicy.s2t6p2') }}</p>
      <p lang="privacyPolicy.s2t6p3">{{ t('privacyPolicy.s2t6p3') }}</p>

      <h2 class="headline text-capitalize mb-5" lang="privacyPolicy.s2t7">{{ t('privacyPolicy.s2t7') }}</h2>
      <p lang="privacyPolicy.s2t7p1">{{ t('privacyPolicy.s2t7p1') }}</p>

      <h2 class="headline text-capitalize mb-5" lang="privacyPolicy.s2t8">{{ t('privacyPolicy.s2t8') }}</h2>
      <p lang="privacyPolicy.s2t8p1">{{ t('privacyPolicy.s2t8p1') }}</p>

      <h2 class="headline text-capitalize mb-5" lang="privacyPolicy.s2t9">{{ t('privacyPolicy.s2t9') }}</h2>
      <p lang="privacyPolicy.s2t9p1">{{ t('privacyPolicy.s2t9p1') }}</p>

      <h2 class="headline text-capitalize mb-5" lang="privacyPolicy.s2t10">{{ t('privacyPolicy.s2t10') }}</h2>
      <p lang="privacyPolicy.s2t10p1">{{ t('privacyPolicy.s2t10p1') }}</p>
      <p class="mt-10" lang="privacyPolicy.s2t10p2">{{ t('privacyPolicy.s2t10p2') }}</p>

    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="$store.dispatch('SET_DIALOG_STATE', false);"
      >Ok</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import lang from '@/mixins/lang';

export default {
  name: 'privacyPolicy',
  mixins: [lang],
};
</script>

<style lang="scss">
  .pad-bottom {
    padding-bottom: 16px;
  }

  .underline {
    text-decoration: underline;
  }
</style>
