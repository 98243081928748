/* eslint-disable */
import getCommonByLang from './common';

export default {
  common: getCommonByLang('es'),
  verification: {
    title: 'Empecemos',
    startText: {
      p1: 'Bienvenido a la página web de Exchange Service Center (Centro de servicio de intercambio). Para solicitar que se implemente, se levante temporalmente o se elimine permanentemente un congelamiento de seguridad de su informe de datos de Exchange Service Center, proporcione la información de identificación personal que se solicita a continuación. Su información de identificación personal solo se utilizará para esta solicitud de congelamiento de seguridad y no se utilizará con fines de marketing.',
      p2: 'Esta información es necesaria para verificar su identidad. Los campos del formulario marcados con un asterisco (*) son obligatorios. ',
    },
    form: {
      firstName: 'Nombre',
      lastName: 'Apellido',
      initial: 'Inicial',
      suffix: 'Sufijo',
      ssn: 'Número de Seguro Social',
      dob: 'Fecha de nacimiento',
      address: 'Dirección actual',
      city: 'Ciudad',
      state: 'Estado',
      zip: 'Código postal',
      accept: 'Acepto los',
      continueText: 'Para continuar usando el sitio web de Exchange Service Center Security Freeze, acepte los siguientes términos de uso.',
      termsOfUse: 'Términos de uso',
      validation: {
        requiredFirstName: 'Ingrese el primer nombre',
        requiredLastName: 'Ingrese el apellido',
        requiredSsn: 'Ingrese el SSN',
        requiredBirthdate: 'Ingrese la fecha de nacimiento (mm/dd/aaaa)',
        requiredAddress: 'Ingrese la dirección actual',
        requiredCity: 'Ingrese la ciudad',
        requiredState: 'Ingrese el estado',
        requiredZip: 'Ingrese el código portal',
        requiredAcceptTerms: 'Debe aceptar los términos de uso para continuar!',
        name: 'Ingrese únicamente letras, guiones, o espacios',
        address: 'Please Enter Only Numbers, Letters, Spaces, \'-\', \'.\' or \'/\'.',
        noNumbersInAddress: 'Please Enter Apartment Number, House Number Or P.O.Box Number In The Address field',
        noLettersInAddress: 'Please Enter The House/Plot Name, Street Name, Area Name',
        city: 'Ingrese únicamente letras, guiones, puntos o espacios',
        zip: 'Ingrese 5 dígitos en el código postal',
        ssn: 'SSN no válido (Formato esperado: XXX-XX-XXXX)',
        date: 'Fecha inválida',
      },
    },
  },
  additionalInfo: {
    title: 'Información adicional requerida',
    p1: 'Lo sentimos... no podemos procesar su solicitud en línea con relación a un congelamiento de seguridad de Exchange Service Center. Para ayudarnos a procesar su solicitud, envíe por escrito los datos requeridos que se detallan a continuación.',
    p2: 'Para obtener asistencia: de lunes a viernes de 8:00 a.m. a 5:00 p.m. en su zona horaria llame al 1-866-343-2821',
    p4: 'Para todas las solicitudes de congelamiento de seguridad, envíe la siguiente información de identificación: su nombre completo incluido cualquier sufijo (p. ej., Jr., Sr., etc.), dirección completa, número de Seguro Social y fecha de nacimiento.',
    p5: 'Para la implementación de un congelamiento de seguridad, proporcione la información de identificación personal requerida arriba.',
    p6: 'Para levantar temporalmente un congelamiento de seguridad debe enviar todos los siguientes datos junto con la información de identificación personal requerida:',
    li1: 'Número de identificación personal de 10 dígitos (PIN)',
    li2: 'Rango de fechas (p. ej., del 15 al 21 de marzo) para el levantamiento temporal del congelamiento de seguridad, o la información correcta sobre el tercero o terceros que recibirán su informe de datos',
    p7: 'Para eliminar permanentemente un congelamiento de seguridad, debe enviar todos los siguientes datos junto con la información de identificación personal requerida:',
    li3: 'Número de identificación personal de 10 dígitos (PIN)',
    li4: 'Dos (2) formas de identificación (p.ej., talón de pago con dirección y factura de servicios públicos)',
    p8: 'Envíe la información de identificación personal requerida junto con cualquier otra información personal que se haya indicado anteriormente a:',
    p9: 'Exchange Service Center',
    p10_1: 'Congelamiento de seguridad',
    p10_2: 'P.O. Box 105561',
    p10_3: 'Atlanta, GA 30348',
    p11: 'Muchas gracias,',
    p12: 'Exchange Service Center',
    btn1: 'Regresar a Verificación',
  },
  serviceOptions: {
    title1: 'Su informe de datos de',
    title2: 'National Consumer Telecommunications and Utilities Data Exchange (NCTUE)',
    title3: 'Data report ',
    title4_1: 'esta congelado',
    title4_2: 'no esta congelado',
    p1: 'Usted puede implementar, levantar temporalmente o eliminar un congelamiento de seguridad en su informe de datos de telecomunicaciones y servicios públicos según la ley estatal o el programa de congelamiento voluntario de seguridad de Exchange Service Center.',
    p2: 'El congelamiento de seguridad está diseñado para evitar que la información contenida en el informe de datos de Exchange Service Center sea divulgada a terceros, como proveedores de servicios y otras empresas, excepto a aquellos exentos por ley o a aquellos para los cuales usted nos contactó y solicitó que levantemos temporalmente el congelamiento de seguridad o a aquellos que tienen acceso durante el período de tiempo para el que usted solicitó levantar temporalmente el congelamiento de seguridad.',
    p3: 'Aunque algunas leyes estatales nos permiten cobrar por solicitudes de congelamiento de seguridad, Exchange Service Center nunca cobra por ningún tipo de solicitud de congelamiento de seguridad.',
    p4: 'Your National Consumer Telecommunications and Utilities Data Exchange (NCTUE) Data report is Frozen',
    p5_1: 'Si desea revisar la información general sobre las congelaciones de seguridad, ',
    p5_2: '.',
    btnManageFreeze: 'Manejar el congelamiento de seguridad',
    btnPlaceFreeze: 'Implementar congelamiento de seguridad',
  },
  generalInfo: {
    title: 'Información General',
    s1: 'Lo que es:',
    s1p1: 'Usted puede implementar, levantar temporalmente o eliminar un congelamiento de seguridad en su informe de datos de telecomunicaciones y servicios públicos según la ley estatal o el programa de congelamiento voluntario de seguridad de Exchange Service Center. El congelamiento de seguridad está diseñado para evitar que la información contenida en el informe de datos de Exchange Service Center sea divulgada a terceros, como proveedores de servicios y otras empresas, excepto a aquellos exentos por ley o a aquellos para los cuales usted nos contactó y solicitó que levantemos temporalmente el congelamiento de seguridad o a aquellos que tienen acceso durante el período de tiempo para el que usted solicitó levantar temporalmente el congelamiento de seguridad. Aunque algunas leyes estatales nos permiten cobrar por solicitudes de congelamiento de seguridad, Exchange Service Center nunca cobra por ningún tipo de solicitud de congelamiento de seguridad.',
    s2: ' Quienes pueden solicitarlo:',
    s2p1: 'Solo usted puede solicitar que se implemente un congelamiento de seguridad en el informe de datos de Exchange Service Center y solo usted puede solicitar que se elimine o que se levante temporalmente. Además, normalmente el congelamiento de seguridad permanecerá en el informe de datos de Exchange Service Center hasta que: 1) usted solicita que se elimine o 2) usted solicita el levantamiento temporal del congelamiento de seguridad para uno o varios terceros específicos, o un período de tiempo específico, según corresponda según la ley estatal o el programa de congelamiento voluntario de seguridad de Exchange Service Center. ',
    s2p2: 'Además de las leyes de congelamiento de seguridad para adultos, muchos estados también tienen una ley de congelamiento de seguridad para el consumidor que permite a los padres o tutores congelar los datos del menor o adulto incapacitado del que son responsables. Usted debe proporcionar prueba de su identidad como representante del consumidor protegido, prueba de la identidad del consumidor protegido y prueba de su autoridad para actuar en nombre del consumidor protegido. Todas las solicitudes de congelamiento de seguridad del consumidor deben enviarse por correo a la dirección que se muestra a continuación.',
    s3: 'Necesidad de planear con anticipación:',
    s3p1: 'Si decide implementar un congelamiento de seguridad en su informe de datos, asegúrese de planificar con anticipación todas sus solicitudes de crédito. En virtud de las leyes de algunos estados, puede tomar hasta tres días hábiles procesar una solicitud para levantar temporalmente un congelamiento de seguridad. Además, es posible que no pueda solicitar el levantamiento temporal de un congelamiento de seguridad fuera del horario laboral o los fines de semana.',
    s4: ' Envío de la solicitud de congelamiento por correo:',
    s4p1: 'Aunque algunos estados permiten que se implemente un congelamiento de seguridad, se levante temporalmente o se elimine de otras maneras, en la mayoría de los casos para solicitar que se implemente o elimine un congelamiento de seguridad en el informe de datos de Exchange Service Center, debe realizar su solicitud por escrito y enviarla por correo',
    s4p2_1: 'Para enviar sus solicitudes de congelamiento de seguridad, envíe la siguiente información de identificación personal: ',
    s4p2_2: 'su nombre completo incluido cualquier sufijo (p. ej., Jr., Sr., etc.), dirección completa, número de Seguro Social y fecha de nacimiento.',
    s4l1_1: 'Para la implementación de un congelamiento de seguridad,',
    s4l1_2: 'proporcione por correo la información de identificación personal requerida arriba.',
    s4l2_1: 'Para levantar temporalmente un congelamiento de seguridad,',
    s4l2_2: 'debe enviar todos los siguientes datos junto con la información de identificación personal requerida:',
    s4l2l1: 'Número de identificación personal de 10 dígitos (PIN)',
    s4l2l2: 'Rango de fechas, si su estado permite utilizar un rango de fechas (p. ej., del 15 al 21 de marzo) para el levantamiento temporal del congelamiento de seguridad',
    s4l2l3: 'Información correcta sobre el tercero que recibirá su informe de datos, si su estado permite el levantamiento para terceros.',
    s4l3_1: 'Para eliminar permanentemente un congelamiento de seguridad,',
    s4l3_2: 'debe enviar todos los siguientes datos junto con la información de identificación personal requerida:',
    s4l3l1: 'Su nombre completo incluido cualquier sufijo (p. ej., Jr., Sr., etc.), dirección completa, número de Seguro Social y fecha de nacimiento',
    s4l3l2: 'Dos (2) formas de identificación (p. ej., talón de pago con dirección y factura de servicios públicos que muestre la dirección actual)',
    s4l4_1: 'Para solicitar un número de identificación personal (PIN) de 10 dígitos de reemplazo,',
    s4l4_2: 'debe presentar lo siguiente junto con la información de identificación personal requerida:',
    s4l4l1: 'Su nombre completo incluido cualquier sufijo (p. ej., Jr., Sr., etc.), dirección completa, número de Seguro Social y fecha de nacimiento',
    s4l4l2: 'Dos (2) formas de identificación (p. ej., talón de pago con dirección y factura de servicios públicos que muestre la dirección actual)',
    s4p3: 'Envíe la información de identificación personal requerida junto con cualquier otra información adicional que se haya indicado anteriormente a:',
    s5l1: 'Exchange Service Center',
    s5l2: 'P.O. Box 105561',
    s5l3: 'Atlanta, GA 30348',
    s6: 'Cargos por congelamiento de seguridad:',
    s6p1: 'Aunque algunas leyes estatales nos permiten cobrar cargos por ciertas solicitudes de congelamiento de seguridad, Exchange Service Center no cobra ningún cargo por un PIN de reemplazo, ni por implementar, levantar temporalmente o eliminar permanentemente un congelamiento de seguridad.',
    s7: 'Confirmación de solicitud de congelamiento de seguridad:',
    s7p1: 'Una vez que implementemos, levantemos temporalmente o eliminemos un congelamiento de seguridad en su informe de datos de Exchange Service Center, le proporcionaremos una confirmación. Asegúrese de conservar el número de identificación personal (PIN) de 10 dígitos que se le proporcionó al implementar el congelamiento de seguridad, ya que lo necesitará para solicitar un levantamiento temporal o la eliminación de su congelamiento de seguridad. Si pierde su PIN, puede solicitar un nuevo PIN enviando una solicitud por correo a la dirección indicada anteriormente.',
    s8: 'Equifax/Experian/TransUnion:',
    s8p1: 'Para presentar una solicitud de congelamiento de seguridad con las agencias de informes de crédito del consumidor de todo el país, comuníquese con Equifax, Experian y TransUnion:',
    s8l1: 'Equifax, P.O. Box 105788, Atlanta, GA 30348-5788, (888) 298-0045 o www.equifax.com',
    s8l2: 'TransUnion LLC, P.O. Box 2000, Chester, PA 19016, (800) 680-7289 o www.transunion.com',
    s8l3: 'Experian, P.O. Box 9554, Allen, TX 75013, (888) 397-3742 o www.experian.com',
    s8p2: 'Si necesita ayuda, puede comunicarse con Exchange Service Center al 1-866-343-2821',
    title2: 'Preguntas frecuentes sobre protección al crédito',
    s9: 'Preguntas que escuchamos. Respuestas que necesita.',
    q: 'P.',
    a: 'R.',
    s10: '¿Qué derechos tengo para solucionar los efectos del fraude o el robo de identidad?',
    s10p1: 'Para obtener información acerca de sus derechos en virtud de la Ley de Equidad en la Información sobre el Crédito (Fair Credit Reporting Act, FCRA) y sus derechos proporcionados por la Comisión Federal de Comercio (Federal Trade Commission, FTC) visite',
    s10l1: 'https://www.identitytheft.gov/',
    s10l2: 'https://files.consumerfinance.gov/f/documents/bcfp_consumer-identity-theft-rights-summary_2018-09.docx',
    s10p2: 'o',
    s11: '¿Cómo puedo eliminar una alerta de fraude de mi informe de datos?',
    s11p1: 'Se requiere una solicitud por escrito para eliminar una alerta de fraude de su archivo. Puede escribir a Exchange Service Center a la siguiente dirección:',
    s11a00: 'Exchange Service Center',
    s11a01: 'PO Box 105425',
    s11a02: 'Atlanta, GA 30348-5425',
    s11p3: 'Asegúrese de incluir su nombre, número de seguro social, dirección actual y anterior, fecha de nacimiento y número de teléfono.',
    s12: 'Información de contacto importante para las víctimas de robo de identidad.',
    s12p1: 'Hay una serie de servicios útiles para ayudarlo a responder si ha sido víctima de robo de identidad. A continuación encontrará una lista de recursos que hemos recopilado en su nombre.',
    s12a10: 'Línea Directa de Robo de Identidad (Identity Theft Hotline) de la Comisión Federal de Comercio',
    s12a11: '877/ID-THEFT',
    s12a20: 'Equifax Information Services LLC',
    s12a21: '800-525-6285',
    s12a22: 'P.O. Box 740250',
    s12a23: 'Atlanta, GA 30374',
    s12a30: 'www.fraudalerts.equifax.com',
    s12a40: 'Experian fraud division',
    s12a41: ' 888-397-3742',
    s12a42: ' P.O. Box 1017',
    s12a43: 'Allen, TX 75013',
    s12a50: 'Trans Union fraud division',
    s12a51: '800-916-8800',
    s12a52: 'P.O. Box 2000',
    s12a53: 'Chester, PA 19022',
    s13: 'Mi cónyuge o padre/madre ha fallecido, ¿qué sucede con su informe de datos?',
    s13p1: 'Debe notificar a Exchange Service Center sobre el fallecimiento de su cónyuge o padre/madre, para que el Exchange Service Center pueda actualizar los registros según corresponda. Escriba a:',
    s13a00: 'Exchange Service Center',
    s13a01: 'P.O. Box 105398',
    s13a02: 'Atlanta GA 30348-5398',
    s13a03: 'Incluya una copia del certificado de defunción.',
    b1: 'Regresar a las opciones de congelamiento de seguridad',
  },
  manageFreeze: {
    p1: 'Seleccione la opción de congelamiento de seguridad que desea solicitar (solo puede seleccionar una opción a la vez):',
    opt_C: 'Levantar temporalmente un congelamiento de seguridad por rango de fechas',
    opt_B: 'Levantar temporalmente un congelamiento de seguridad por tercero específico',
    opt_M: 'Levantar temporalmente un congelamiento de seguridad por terceros múltiples',
    opt_D: 'Eliminar permanentemente un congelamiento de seguridad',
  },
  placeFreeze: {
    p1: 'Está a punto de ',
    p1_1: 'implementar un congelamiento de seguridad',
    p1_2: ' en su informe de datos de National Consumer Telecommunications and Utilities Data Exchange (NCTUE)',
  },
  confirmation: {
    title1: 'Su solicitud de congelamiento de seguridad',
    title2: '',
    opt_A: ' ha sido procesada',
    opt_B: ' ha sido procesada',
    opt_C: ' ha sido procesada',
    opt_D: ' ha sido procesada',
    opt_M: ' ha sido procesada',
    print_A: 'Imprima y guarde el siguiente documento para tenerlo como registro. Debe imprimir el documento mediante el botón Imprimir de Adobe que aparece a continuación. La información proporcionada es necesaria para poder levantar temporalmente o eliminar permanentemente el congelamiento de seguridad de su archivo de crédito en el futuro.',
    print_B: 'Imprima y guarde el siguiente documento para tenerlo como registro. Debe imprimir el documento mediante el botón Imprimir de Adobe que aparece a continuación.',
    print_C: 'Imprima y guarde el siguiente documento para tenerlo como registro. Debe imprimir el documento mediante el botón Imprimir de Adobe que aparece a continuación.',
    print_D: 'Imprima y guarde el siguiente documento para tenerlo como registro. Debe imprimir el documento mediante el botón Imprimir de Adobe que aparece a continuación.',
    print_M: 'Imprima y guarde el siguiente documento para tenerlo como registro. Debe imprimir el documento mediante el botón Imprimir de Adobe que aparece a continuación.',
    download: 'Descargar la confirmación (PDF)',
    return: 'Regresar a las opciones de congelamiento de seguridad',
    text1: 'Si no puede visualizar su PDF de uso único, llame al 1‑866‑343‑2821.',
    text2: 'Para realizar una solicitud de congelamiento de seguridad con las otras agencias nacionales de informes de crédito del consumidor, comuníquese con Equifax, Experian y TransUnion:',
    text3: 'Equifax, P.O. Box 105788 Atlanta, GA 30348-5788: (800)349-9960',
    text4: 'Experian, P.O. Box 9554, Allen, TX 75013 (888)379-3742',
    text5: 'TransUnion, P.O. Box 6790, Fullerton, CA 92834 (888)909-8872',
  },
  liftFreeze: {
    title1: 'Levantamiento temporal de un congelamiento de seguridad por rango de fechas',
    // eslint-disable-next-line
    p1: '	Está a punto de levantar temporalmente un congelamiento de seguridad en su archivo de crédito de Exchange Service Center.',
    title2: 'Levantar temporalmente mi congelamiento de seguridad para el siguiente rango de fechas',
    p2: 'Especifique el PIN de 10 dígitos asociado con el congelamiento de seguridad en el archivo de crédito de Exchange Service Center. Su PIN de 10 dígitos es necesario para solicitar que se levante temporalmente su congelamiento de seguridad.',
    p3: 'Especifique el PIN de 10 dígitos asociado con el congelamiento de seguridad en el archivo de crédito de Exchange Service Center. Su PIN de 10 dígitos es necesario para solicitar que se elimine permanentemente su congelamiento de seguridad.',
    titleMP1: 'Levantar temporalmente un congelamiento de seguridad por terceros múltiples',
    titleMP2: 'Levantar temporalmente mi congelamiento de seguridad para la siguiente cantidad de terceros. ',
    titleMP2note1: 'Usted puede elegir entre ',
    titleMP2note2: ' y ',
    titleMP2note3: ' terceros a continuación',
    titleSP1: 'Levantar temporalmente un congelamiento de seguridad por tercero específico',
    titleSP2: 'Levantar temporalmente mi congelamiento de seguridad para el siguiente tercero:',
    form: {
      dateFrom: 'Desde',
      dateTo: 'Hasta',
      pin: 'PIN de 10 dígitos',
      parties: 'Cantidad de terceros',
      partyName: 'Nombre del tercero',
      validation: {
        requiredFrom: 'Ingrese la fecha de inicio (mm/dd/aaaa)',
        requiredTo: 'Ingrese la fecha final (mm/dd/aaaa)',
        requiredPinCode: 'Ingrese el PIN de 10 dígitos',
        requiredParties: 'Seleccione el número de compañias',
        requiredPartyName: 'Ingrese el PIN de Nombre del tercero',
        pin: 'Ingrese únicamente numeros',
        party: 'Ingrese únicamente letras para el nombre de la compañia',
        dateRange1: 'La fecha inicial no puede ser menor a la fecha actual',
        dateRange2: 'La fecha final no puede ser la misma o mayor que la fecha inicial',
        dateRange3: 'La diferencia entre la fecha inicial y la fecha final no puede ser mayor a un año',
      },
    },
  },
  removeFreeze: {
    title1: 'Eliminar permanentemente un congelamiento de seguridad',
    p1: 'Está a punto de eliminar permanentemente un congelamiento de seguridad en su archivo de crédito de Exchange Service Center.',
    title2: 'Eliminar permanentemente un congelamiento de seguridad:',
  },
  breadcrumb: {
    verification: 'Verificación',
    additionalInformation: 'Additional Information',
    serviceOptions: 'Opciones de Servicio',
    generalInfo: 'Información General',
    manageFreeze: 'Manejar el congelamiento de seguridad',
    placeFreeze: 'Implementar congelamiento de seguridad',
    liftFreeze: 'Levantar temporalmente un congelamiento de seguridad',
    removeFreeze: 'Eliminar permanentemente un congelamiento de seguridad',
    confirmation: 'Confirmación',
  },
  privacyPolicy: {
    title: 'Políticas de Privacidad',
    title2: 'Exchange Service Center – Online Privacy Policy',
    s1p1: 'Welcome to the Exchange Service Center web site. The Exchange Service Center provides consumer services on behalf of the National Consumer Telecommunications Utility Exchange (NCTUE). The Exchange Service Center is operated by Equifax.',
    s1p2: 'At this web site, you can add, temporarily lift, or permanently remove a security freeze from your Exchange Data Report. Please carefully read this privacy policy to understand how we will treat the information you provide while visiting the web site. This policy may change from time to time. Please check the policy each time you use our Web site for the most current information',
    s1p3: 'We take reasonable steps to give you, as a visitor to this web site, clear notice of:',
    s1p4: 'We take reasonable steps to:',
    s1l11: 'when we are requesting information from you;',
    s1l12: 'the types of information we request from you;',
    s1l13: 'the general purposes for which that information will be used or disclosed;',
    s1l14: 'the categories of users to whom we provide the information; and',
    s1l21: 'Maintain accurate records and provide you access to your Exchange Data Report and a way to update and correct such information;',
    s1l22: 'Use safe and secure systems, including physical, administrative, and technical security procedures to safeguard the information about you.',
    title3: 'COLLECTION AND USE OF INFORMATION',
    s2t1: 'Information You Provide',
    s2t1p1: 'When you provide information through this web site to request a security freeze, a temporary lift, or a permanent removal of a security freeze on your Exchange Data Report online, the information we collect from you is only used to handle those requests. You may provide to us certain personally identifiable consumer information, such as first and last name; home address, billing address, or other physical address; a Social Security number; or certain other information, such as date of birth, and credit card account information if payment is required (collectively, “Personally Identifiable Consumer Information”). In general, we will use the information collected from you for the purpose of fulfilling your security freeze request. We will not release your Personally Identifiable Consumer Information to third parties except: (1) as necessary for us to process your security freeze request or (2) as permitted by law. These third parties are not allowed to use Personally Identifiable Consumer Information except for the purpose of providing these services.',
    s2t2: 'Information From Others',
    s2t2p1: 'We may also collect information about your transactions with us, our affiliates, or others, such as NCTUE, in connection with fulfilling your security freeze requests.',
    s2t3: 'How We Use The Information We Collect',
    s2t3p1: 'We use the information we collect about you to handle the security freeze requests you make to us. If you request a security freeze, a temporary lift, or a permanent removal of a security freeze on your Exchange Data Report online, the information we collect from you is only used to handle those requests and possibly update your Exchange Data Report.',
    s2t4: 'To Whom We May Disclose The Information We Collect',
    s2t4p1: 'When you request a security freeze, a temporary lift, or a permanent removal of a security freeze on your Exchange Data Report online, we may disclose any of the information we collect from or about you, as described above, to affiliated companies, as well as non-affiliated third parties (companies that are not owned or controlled by us). The information provided to affiliates and non-affiliated third parties is disclosed only as required to process and fulfill these requests for you.',
    s2t4p2: 'We take reasonable precautions to be sure that nonaffiliated third parties and affiliates, to whom we may disclose Personally Identifiable Consumer Information, as described above, are aware of our privacy policy and fair information principles and will treat the information in a similarly responsible manner. Our contracts and written agreement with nonaffiliated third parties that receive information from us about you prevent further transfer of the information.',
    s2t4p3: 'We may also disclose any information collected from you or about you through this web site as required by law or legal process.',
    s2t5: 'ACCESS TO YOUR INFORMATION',
    s2t5p1: 'You may order a disclosure copy of your Exchange Data Report by mailing your request to Exchange Service Center, PO Box 105161, Atlanta, GA 30348 or by calling 866-343-2821. If you believe that there is an error on your Exchange Data Report, you may file a dispute by writing to Exchange Service Center, PO Box 105398, Atlanta, GA 30348.',
    s2t6: 'SECURITY AND CONFIDENTIALITY',
    s2t6p1: 'The Exchange Service Center recognizes the importance of secure online transactions, and we maintain reasonable physical, administrative, and technical safeguards to protect your Personally Identifiable Consumer Information and the other information we collect about you, as described above. We safeguard the privacy of information you provide us through online forms. For online requests, we use programs that encrypt the information you provide on the form before transmission to the Exchange Service Center. Information you provide to us online, including Personally Identifiable Consumer Information is transmitted to us through a secured socket layer (SSL) transmission. The information is decrypted only upon receipt by The Exchange Service Center.',
    s2t6p2: 'Except as stated in the "To Whom We May Disclose The Information We Collect" section above, we restrict access to Personally Identifiable Consumer Information that is collected about you to only those who have a need to know that information in connection with the purposes for which it is collected and used. This web site does not use or collect cookies.',
    s2t6p3: 'Additionally, we have security protocols and measures in place to reasonably protect the Personally Identifiable Consumer Information and other information we maintain about you from unauthorized access or alteration. These measures include internal and external firewalls, physical security and technological security measures, and encryption of certain data.',
    s2t7: 'CHILDREN\'S PRIVACY',
    s2t7p1: 'This web site where we may collect Personally Identifiable Consumer Information in conjunction with a transaction involving a request for initial placement, a temporary lift, or a permanent removal of a security freeze on your Exchange Data Report online, is intended for use only by adults. We comply with the Children\'s Online Privacy Protection Act and do not knowingly solicit or collect information from children.',
    s2t8: 'APPLICABILITY OF PRIVACY POLICY TO INDIVIDUAL USE ONLY',
    s2t8p1: 'This online privacy policy applies to individuals using this web site to request the services provided through this web site for personal, family or household purposes.',
    s2t9: 'KEEPING UP-TO-DATE ON OUR PRIVACY POLICY CHANGES',
    s2t9p1: 'We want you to be informed about how we protect your privacy. We may change our privacy policy in the future, but we will not change our practices until they have been posted at this web site. If changes occur, we will also show the date of revision. By using our web site to which this privacy policy applies you agree that we may notify you of changes in our privacy policy through this web site.',
    s2t10: 'HOW TO CONTACT US WITH QUESTIONS OR COMMENTS',
    s2t10p1: 'If you have questions or comments about Exchange Service Center’s online privacy policy and fair information practices, please e-mail us at NCTUE@exchangeservicecenter.com or write to Exchange Service Center, P.O. Box 105832, Atlanta, GA 30348. If you have specific questions about your Exchange Data Report you may e-mail us at NCTUE@exchangeservicecenter.com.',
    s2t10p2: 'Effective Date: February 17, 2011',
  },
};
