import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import { FREEZE_OPTIONS } from './services/constants';

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'verification',
      component: () => import('./views/Verification.vue'),
      meta: {
      },
    },
    {
      path: '/additional-information',
      name: 'additional-information',
      component: () => import('./views/AdditionalInformation.vue'),
      meta: {
      },
    },
    {
      path: '/service-options',
      name: 'service-options',
      component: () => import('./views/ServiceOptions.vue'),
      meta: {
        breadcrumb: ['breadcrumb.verification', 'breadcrumb.serviceOptions'],
      },
    },
    {
      path: '/general-info',
      name: 'general-info',
      component: () => import('./views/GeneralInfo.vue'),
      meta: {
        breadcrumb: [
          'breadcrumb.verification',
          'breadcrumb.serviceOptions',
          'breadcrumb.generalInfo'],
      },
      beforeEnter(to, from, next) {
        if (from.name !== 'service-options') {
          next({ name: from.name });
          return;
        }

        next();
      },
    },
    {
      path: '/manage-freeze',
      name: 'manage-freeze',
      component: () => import('./views/ManageFreeze.vue'),
      meta: {
        breadcrumb: [
          'breadcrumb.verification',
          'breadcrumb.serviceOptions',
          'breadcrumb.manageFreeze'],
      },
      beforeEnter(to, from, next) {
        if (store.getters.availableActions.includes(FREEZE_OPTIONS.PLACE_FREEZE.code)) {
          next({ name: 'place-freeze' });
          return;
        }

        next();
      },
    },
    {
      path: '/place-freeze',
      name: 'place-freeze',
      component: () => import('./views/PlaceFreeze.vue'),
      meta: {
        breadcrumb: [
          'breadcrumb.verification',
          'breadcrumb.serviceOptions',
          'breadcrumb.placeFreeze'],
      },
      beforeEnter(to, from, next) {
        if (!store.getters.availableActions.includes(FREEZE_OPTIONS.PLACE_FREEZE.code)) {
          next({ name: 'manage-freeze' });
          return;
        }

        next();
      },
    },
    {
      path: '/confirmation',
      name: 'confirmation',
      component: () => import('./views/Confirmation.vue'),
      meta: {
        breadcrumb: [
          'breadcrumb.verification',
          'breadcrumb.serviceOptions',
          'breadcrumb.placeFreeze',
          'breadcrumb.confirmation',
        ],
      },
      beforeEnter(to, from, next) {
        if (!store.state.common.documentReference) {
          next({ name: from.name });
          return;
        }

        next();
      },
    },
    {
      path: '/confirmation-remove-freeze',
      name: 'confirmation-remove-freeze',
      component: () => import('./views/Confirmation.vue'),
      meta: {
        breadcrumb: [
          'breadcrumb.verification',
          'breadcrumb.serviceOptions',
          'breadcrumb.manageFreeze',
          'breadcrumb.removeFreeze',
          'breadcrumb.confirmation',
        ],
      },
      beforeEnter(to, from, next) {
        if (!store.state.common.documentReference) {
          next({ name: from.name });
          return;
        }

        next();
      },
    },
    {
      path: '/confirmation-lift-freeze',
      name: 'confirmation-lift-freeze',
      component: () => import('./views/Confirmation.vue'),
      meta: {
        breadcrumb: [
          'breadcrumb.verification',
          'breadcrumb.serviceOptions',
          'breadcrumb.manageFreeze',
          'breadcrumb.liftFreeze',
          'breadcrumb.confirmation',
        ],
      },
      beforeEnter(to, from, next) {
        if (!store.state.common.documentReference) {
          next({ name: from.name });
          return;
        }

        next();
      },
    },
    {
      path: '/lift-freeze-by-daterange',
      name: 'lift-freeze-daterange',
      component: () => import('./views/LiftFreezeByDateRange.vue'),
      meta: {
        breadcrumb: [
          'breadcrumb.verification',
          'breadcrumb.serviceOptions',
          'breadcrumb.manageFreeze',
          'breadcrumb.liftFreeze'],
      },
      beforeEnter(to, from, next) {
        if (!store.getters.availableActions.includes(FREEZE_OPTIONS.DATE_RANGE_LIFT.code)
          || store.state.consumer.freezeOption !== FREEZE_OPTIONS.DATE_RANGE_LIFT.code) {
          next({ name: 'manage-freeze' });
          return;
        }

        next();
      },
    },
    {
      path: '/lift-freeze-by-parties',
      name: 'lift-freeze-parties',
      component: () => import('./views/LiftFreezeByMultipleParties.vue'),
      meta: {
        breadcrumb: [
          'breadcrumb.verification',
          'breadcrumb.serviceOptions',
          'breadcrumb.manageFreeze',
          'breadcrumb.liftFreeze'],
      },
      beforeEnter(to, from, next) {
        if (!store.getters.availableActions.includes(FREEZE_OPTIONS.PINS_LIFT.code)
          || store.state.consumer.freezeOption !== FREEZE_OPTIONS.PINS_LIFT.code) {
          next({ name: 'manage-freeze' });
          return;
        }

        next();
      },
    },
    {
      path: '/lift-freeze-by-party',
      name: 'lift-freeze-party',
      component: () => import('./views/LiftFreezeBySingleParty.vue'),
      meta: {
        breadcrumb: [
          'breadcrumb.verification',
          'breadcrumb.serviceOptions',
          'breadcrumb.manageFreeze',
          'breadcrumb.liftFreeze'],
      },
      beforeEnter(to, from, next) {
        if (!store.getters.availableActions.includes(FREEZE_OPTIONS.PIN_LIFT.code)
          || store.state.consumer.freezeOption !== FREEZE_OPTIONS.PIN_LIFT.code) {
          next({ name: 'manage-freeze' });
          return;
        }

        next();
      },
    },
    {
      path: '/remove-freeze',
      name: 'remove-freeze',
      component: () => import('./views/RemoveFreeze.vue'),
      meta: {
        breadcrumb: [
          'breadcrumb.verification',
          'breadcrumb.serviceOptions',
          'breadcrumb.manageFreeze',
          'breadcrumb.removeFreeze'],
      },
      beforeEnter(to, from, next) {
        if (!store.getters.availableActions.includes(FREEZE_OPTIONS.REMOVE_FREEZE.code)
          || store.state.consumer.freezeOption !== FREEZE_OPTIONS.REMOVE_FREEZE.code) {
          next({ name: 'manage-freeze' });
          return;
        }

        next();
      },
    },
    {
      path: '*',
      name: 'non-existing',
      component: () => import('./views/Verification.vue'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if ((to.name !== 'verification' && to.name !== 'additional-information' && !store.getters.profile) || (to.name === 'non-existing')) {
    next({ name: 'verification' });
    return;
  }

  if (to.name && to.name !== 'verification' && from.name.includes('confirmation') && !store.state.common.returnFromConfirmation) {
    next({ name: from.name });
    return;
  }

  if (store.getters.profile && from.name && to.name === 'verification') {
    store.dispatch('SET_DIALOG_CONTENT', { component: 'invalidateProfileAlert', dialogSettings: { width: 770 } });
    store.dispatch('SET_ROUTER_DATA', { next });
    return;
  }

  next();
});

export default router;
