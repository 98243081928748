<template>
  <v-card>
    <v-card-title class="title grey lighten-2 mb-5">Terms of use</v-card-title>
    <v-card-text class="subtitle-2 font-weight-light black--text text--lighten-3">
      <p
        v-for="(key, i) in termsOfUseKeys"
        :key="i"
        :lang="`common.termsOfUse.${key}`"
        >{{ t(`common.termsOfUse.${key}`) }}</p>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="$store.dispatch('SET_DIALOG_STATE', false);"
      >Ok</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import lang from '@/mixins/lang';

export default {
  mixins: [lang],
  data: () => ({
    termsOfUseKeys: ['p1', 'p2', 'p3', 'p4'],
  }),
};
</script>
