<template>
  <v-alert
    class="body-2 font-weight-medium ma-0"
    border="left"
    colored-border
    prominent
    type="warning"
    lang="common.alert.text"
  >
    <v-card flat>
      <v-card-title class="subtitle-1 text-uppercase warning--text py-0 mb-10" lang="common.alert.title">
        {{ t('common.alert.title') }}
      </v-card-title>
      <v-card-text class="title font-weight-light black--text text--lighten-3" lang="common.alert.text">
        {{ t('common.alert.text') }}
      </v-card-text>
      <v-divider class="ma-4"></v-divider>
      <v-card-actions>
        <v-btn
            text
            color="primary"
            @click="invalidateSession"
            lang="common.alert.yes"
        >{{ t('common.alert.yes') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="$store.dispatch('SET_DIALOG_STATE', false);"
            lang="common.alert.no"
        >{{ t('common.alert.no') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-alert>
</template>

<script>
import { mapState } from 'vuex';
import lang from '@/mixins/lang';

export default {
  mixins: [lang],
  data: () => ({
  }),
  methods: {
    invalidateSession() {
      this.routerNext();
      this.$store.dispatch('INVALIDATE_PROFILE');
    },
  },
  computed: {
    ...mapState({
      routerNext: (state) => state.common.router.next,
    }),
  },
};
</script>
